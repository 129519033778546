import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
  Row,
} from "reactstrap";

const BestSellers = () => (
  <div>
    <h2>Check out our best sellers!</h2>
    <StaticQuery
      query={bestSellerQuery}
      render={(data) => (
        <div>
          <Row className="d-flex justify-content-center">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div className="bestSellers">
                <Card className="m-4 mens-card" key={node.id}>
                  <Link to={node.fields.slug}>
                    <GatsbyImage
                      className="card-img-top"
                      image={getImage(node.frontmatter.image)}
                      alt={node.frontmatter.description}
                    />
                  </Link>
                  <CardBody>
                    <Link to={node.fields.slug}>
                      <CardTitle className="h4 text-light text-wrap">
                        {node.frontmatter.title}
                      </CardTitle>
                    </Link>
                    <CardSubtitle>{node.frontmatter.description}</CardSubtitle>
                  </CardBody>
                  <CardFooter>
                    <CardSubtitle className="float-left mt-5">
                      Price: ${node.frontmatter.price}
                    </CardSubtitle>
                    <CardSubtitle>
                      <Badge color="danger float-right mt-5">
                        {node.frontmatter.tag}
                      </Badge>
                    </CardSubtitle>
                  </CardFooter>
                </Card>
              </div>
            ))}
          </Row>
        </div>
      )}
    />
  </div>
);

const bestSellerQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tag: { eq: "BEST SELLER" } } }
      limit: 2
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            price
            tag
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [AUTO, JPG]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

export default BestSellers;
